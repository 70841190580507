import {default as propertyPathFromStringToArray} from 'lodash-es/_stringToPath';

/**
 * Переобразует путь к свойству из `string` в `array`.
 *
 * @author lodash library team
 * @since 0.2.0
 * @memberOf module:xwb/object
 * @function propertyPathFromStringToArray
 * @param {string} string Преобразуемый путь в виде строки.
 * @returns {Array} Преобразованный путь в виде массива.
 * @example
 *
 * propertyPathFromStringToArray("obj.prop1.prop2");
 * // => ["obj","prop1","prop2"]
 *
 * propertyPathFromStringToArray("obj.prop1.2.prop2");
 * // => ["obj","prop1","2","prop2"]
 *
 * propertyPathFromStringToArray("obj.prop1[2].prop2");
 * // => ["obj","prop1","2","prop2"]
 *
 */
export default propertyPathFromStringToArray;