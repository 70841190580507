// import xwb main module
import * as main from './main/';

// import xwb/validator module
import * as validator from './validator/';

// import xwb/object module
import * as object from './object/';

// set all imports as xwb global object
if (!window.xwb) window.xwb = {};
window.xwb = {
  ...window.xwb,
  ...main,
  validator,
  ...object
};