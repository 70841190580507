/**
 * Валидаторы числовых типов данных
 */

/**
 * Проверяет на беззнаковое целочисленное значение
 * @author Guschin Mikhail <misha@arkcom.ru>
 * @since 0.1.0
 * @memberOf module:xwb/validator
 * @function isUnsignedInteger
 * @param src {*} Проверяемое значение
 * @return {boolean} Результат проверки
 * @example
 *
 * isUnsignedInteger(-1);
 * // => false
 * isUnsignedInteger('1');
 * // => false
 * isUnsignedInteger(0);
 * // => true
 * isUnsignedInteger(1.1);
 * // => false
 * isUnsignedInteger(1);
 * // => true
 */
export const isUnsignedInteger = (src) => {
  return Number.isInteger(src) && src >= 0;
};

/**
 * Проверяет на позитивное целочисленное значение
 * @author Guschin Mikhail <misha@arkcom.ru>
 * @since 0.1.0
 * @memberOf module:xwb/validator
 * @function isPositiveInteger
 * @param src {*} Проверяемое значение
 * @return {boolean} Результат проверки
 * @example
 *
 * isPositiveInteger(-1);
 * // => false
 * isPositiveInteger('1');
 * // => false
 * isPositiveInteger(0);
 * // => false
 * isPositiveInteger(1.1);
 * // => false
 * isPositiveInteger(1);
 * // => true
 */
export const isPositiveInteger = (src) => {
  return Number.isInteger(src) && src > 0;
};