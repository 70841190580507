/**
 * @module xwb
 * @author ARK Team
 * @author Guschin Mikhail <misha@arkcom.ru>
 * @author Sergey Petrushka <tebis@arkcom.ru>
 * @version 0.1.0
 * @description
 * # Ядреный модуль xwb
 */

/**
 * Объект координат элемента
 * @typedef CoordinatesObj
 * @type {Object}
 * @property {Number} top Расстояние от верхнего края окна до верхней границы элемента, px
 * @property {Number} right Расстояние от левого края окна до правой границы элемента, px
 * @property {Number} bottom Расстояние от верхнего края окна до нижней границы элемента, px
 * @property {Number} left Расстояние от левого края окна до левой границы элемента, px
 * @property {Number} width Ширина элемента, px
 * @property {Number} height Высота элемента, px
 */

/**
 * Константы библиотеки: MODAL_WAIT
 * @constant {String}
 */
export const MODAL_WAIT = '0';
/**
 * Константы библиотеки: MODAL_INFO
 * @constant {String}
 */
export const MODAL_INFO = '1';
/**
 * Константы библиотеки: MODAL_ERROR
 * @constant {String}
 */
export const MODAL_ERROR = '2';
/**
 * Константы библиотеки: MODAL_ASK2BUT
 * @constant {String}
 */
export const MODAL_ASK2BUT = '3';
/**
 * Константы библиотеки: MODAL_ASK3BUT
 * @constant {String}
 */
export const MODAL_ASK3BUT = '4';

/**
 * Вызывает BASIC-подпрограмму
 * @function
 * @param subname {String} Имя подпрограммы
 * @param subparam {String} Параметр, передаваемый через переменную syssubparm
 * @return {Boolean}
 */

export const callSubr = (subname, subparam) => {
  if (!subname) return false;

  document.forms[0].trigger.value = 'sub_' + subname;
  if (subparam) document.forms[0].trigger.value += ',' + subparam;
  document.forms[0].submit();

  return true;
};

/**
 * Вызывает HTML-страницу
 * @function
 * @param pagename {String} Имя WB-шаблона страницы
 * @param pageparam {String} Параметр, передаваемый через переменную syspageparm
 * @return {Boolean}
 */
export const callPage = (pagename, pageparam) => {
  if (!pagename) return false;

  document.forms[0].trigger.value = 'page_' + pagename;
  if (pageparam) document.forms[0].trigger.value += ',' + pageparam;
  document.forms[0].submit();

  return true;
};

/**
 * Открывает URL в новом окне
 * @function
 * @param url {String} URL ресурса
 * @param name {String} Имя нового окна
 * @return {Object | null} Дескриптор открываемого окна
 */
export const openWindow = (url, name) => {
  if (!url) return null;

  let rnd = Math.random().toString().substr(2);
  let src = url + '&rnd=' + rnd + '&datacopy=all';

  return window.open(src, name, 'scrollbars=1, resizable=yes, menubar=1, width=800, height=600');
};

/**
 * Возвращает координаты и размеры элемента страницы
 * @function
 * @param elem {Element} Ссылка на элемент страницы
 * @return {CoordinatesObj | null} Объект с координатами и размерами элемента
 */
export const getElementCoords = (elem) => {
  let body = document.body;
  let docEl = document.documentElement;

  // коородинаты элемента относительно viewport
  let box = elem.getBoundingClientRect();

  if (!box) return null;

  // положение скролла
  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  // отступы document/body от края окна
  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  // координаты элемента на странице
  let obj = {
    top: box.top + scrollTop - clientTop,
    right: box.right + scrollLeft - clientLeft,
    bottom: box.bottom + scrollTop - clientTop,
    left: box.left + scrollLeft - clientLeft,
  };
  obj.width = box.width || obj.right - obj.left;
  obj.height = box.height || obj.bottom - obj.top;

  return obj;
};

/**
 * Заменяет запятую на точку
 * @function
 * @param text {String} Обрабатываемая строка
 * @return {String} Обработанная строка
 */
export const commaToDot = (text) => {
  return text.replace(/,/g, '.');
};

/**
 * Выводит сообщение в режимах WAIT/ERROR/INFO/ASK2BUT/ASK3BUT
 * @function
 * @param mode {Number} Режим вызова
 * @param text {String} Сообщение
 * @param head {String} Шапка сообщения
 * @return {boolean}
 */
export const showModal = (mode, text, head) => {
  let div_m = document.createElement('DIV'); // Create a modal box div
  let div_c = document.createElement('DIV'); // Create a curtain div
  let div_p = document.createElement('DIV'); // Create a parent div
  let div_h = document.createElement('DIV'); // Create a header div
  let div_b = document.createElement('DIV'); // Create a body div
  let div_z = document.createElement('DIV'); // Create a footer div

  div_m.className = 'xwb-modal-parent';
  div_c.className = 'xwb-modal-parent xwb-modal-curtain';
  div_p.className = 'xwb-modal-box';
  div_h.className = 'xwb-modal-box-head';
  div_b.className = 'xwb-modal-box-body';
  div_z.className = 'xwb-modal-box-foot';

  let _t; // = document.createElement(text);
  let but_ok;
  div_b.innerHTML = text;

  switch (mode) {
    case MODAL_WAIT:
      head = head || 'Пожалуйста, подождите...';
      div_z.className += ' xwb-modal-box-foot-wait';
      break;

    case MODAL_INFO:
      head = head || 'Сообщение';
      but_ok = document.createElement('BUTTON'); // Create a OK button

      but_ok.className = 'xwb-modal-box-foot-ok';
      plugEvent(but_ok, 'click', function (evt) {
        document.forms[0].removeChild(div_m);
      });

      _t = document.createTextNode('OK');
      but_ok.appendChild(_t);

      div_z.appendChild(but_ok);
      break;

    case MODAL_ERROR:
      div_h.className += ' xwb-modal-box-head-error';
      head = head || 'Ошибка';
      but_ok = document.createElement('BUTTON'); // Create a OK button

      but_ok.className = 'xwb-modal-box-foot-ok';
      plugEvent(but_ok, 'click', function (evt) {
        document.forms[0].removeChild(div_m);
      });

      _t = document.createTextNode('OK');
      but_ok.appendChild(_t);

      div_z.appendChild(but_ok);
      break;
  }

  let t = document.createTextNode(head); // Create a header text node

  div_h.appendChild(t); // Append the text to header div
  div_p.appendChild(div_h); // Append the header div to parent div
  div_p.appendChild(div_b); // Append the body div to parent div
  div_p.appendChild(div_z); // Append the footer div to parent div
  div_m.appendChild(div_c); // Append the curtain div to modal div
  div_m.appendChild(div_p); // Append the parent div to modal div

  document.forms[0].appendChild(div_m); // Append the modal div to DOM tree

  return true;
};
