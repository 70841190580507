/**
 * Устанавливает значение свойства по пути `path` в объекте `object`. Если свойства, соответствующие
 * частям указанного пути, не существуют, - они создаются.
 *
 * **Внимание:** Функция вызывает мутации в `object`.
 *
 * @author lodash library team
 * @see {@link https://lodash.com/docs/4.17.11#set|Lodash Documentation}
 * @since 0.1.0
 * @memberOf module:xwb/object
 * @function set
 * @param {Object} object Модифицируемый объект
 * @param {Array|string} path Путь к свойству объекта
 * @param {*} value Устанавливаемое значение
 * @returns {Object} Возвращает измененный `object`.
 * @example
 *
 * var object = { 'a': [{ 'b': { 'c': 3 } }] };
 *
 * set(object, 'a[0].b.c', 4);
 * console.log(object.a[0].b.c);
 * // => 4
 *
 * set(object, ['x', '0', 'y', 'z'], 5);
 * console.log(object.x[0].y.z);
 * // => 5
 */
export {default} from 'lodash-es/set';