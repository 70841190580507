/**
 * Возвращает значение свойства по пути `path` из объекта `object`. Если запрашиваемое значение
 * `undefined`, тогда будет возвращено передаваемое в качестве параметра `defaultValue`.
 *
 * @author lodash library team
 * @see {@link https://lodash.com/docs/4.17.11#get|Lodash Documentation}
 * @since 0.1.0
 * @memberOf module:xwb/object
 * @function get
 * @param {Object} object Объект, из которого извлекается значение свойства
 * @param {Array|string} path Путь к свойству объекта
 * @param {*} [defaultValue] Значение по-умолчанию
 * @returns {*} Полученное значение
 * @example
 *
 * var object = { 'a': [{ 'b': { 'c': 3 } }] };
 *
 * get(object, 'a[0].b.c');
 * // => 3
 *
 * get(object, ['a', '0', 'b', 'c']);
 * // => 3
 *
 * get(object, 'a.b.c', 'default');
 * // => 'default'
 */
export {default} from 'lodash-es/get';