/**
 * @module xwb/object
 * @author Guschin Mikhail <misha@arkcom.ru>
 * @version 0.2.0
 * @description
 * # Работа с объектами и их свойствами
 */

export {default as get} from './get';
export {default as set} from './set';
export {default as propertyPathFromStringToArray} from './propertyPathFromStringToArray';